import ModalManager from '@web-nfb/frontend-static/design-system/script/elements/ModalManager'

document.addEventListener('DOMContentLoaded', () => {
    const modalManager = new ModalManager(
      '[data-ui-el="newsletter-terms-button"]',
      '[data-ui-el="newsletter-terms-modal"]',
      'body'
    )
    modalManager.init()

    handleNewsletterForm()
})

const toggleSubmitButton = (button: HTMLButtonElement, enable: boolean) => {
    if (enable) {
        button.removeAttribute('disabled')
    } else {
        button.setAttribute('disabled', true.toString())
    }  
}

const handleInputValidity = (input: HTMLInputElement) => {
    const formElementContainer = input.closest('.nfb-form__element-container') as HTMLElement
    const formValueErrorMessage = formElementContainer.querySelector('[data-ui-el="newsletter-value-error"]') as HTMLElement
    const formEmailErrorMessage = formElementContainer.querySelector('[data-ui-el="newsletter-email-error"]') as HTMLElement

    formValueErrorMessage.dataset.uiShow = 'false'
    formEmailErrorMessage.dataset.uiShow = 'false'

    if (!input.checkValidity()) {
        formElementContainer.classList.remove('nfb-form__element-container--valid')
        formElementContainer.classList.add('nfb-form__element-container--invalid')
    
        if (input.dataset.uiEl === 'nfb-input-text') {
          input.parentElement?.classList.remove('nfb-form__text-container--simple--valid')
          input.parentElement?.classList.add('nfb-form__text-container--simple--invalid')
        }

        const validityState = input.validity

        if (validityState.valueMissing) {
          formValueErrorMessage.dataset.uiShow = 'true'
        }
        if (input.type === 'email' && validityState.typeMismatch) {
            formEmailErrorMessage.dataset.uiShow = 'true'
        }
    
        input.setCustomValidity('')
        return false
    } else {
        formElementContainer.classList.remove('nfb-form__element-container--invalid')
        formElementContainer.classList.add('nfb-form__element-container--valid')

        if (input.dataset.uiEl === 'nfb-input-text') {
            input.parentElement?.classList.remove('nfb-form__text-container--simple--invalid')
            input.parentElement?.classList.add('nfb-form__text-container--simple--valid')
        }
    
        formValueErrorMessage.dataset.uiShow = 'false'
        formEmailErrorMessage.dataset.uiShow = 'false'

        return true
    }
}

const submitForm = (button: HTMLElement, email: string) => {
    button.dataset.uiIsLoading = true.toString()

    let data = new FormData()
    const newsletter: string =  button.dataset.newsletter ?? ''
    data.append('api_key', '4fcf6f89-84a2-5d12-b5d8-b286ea4251a7')
    data.append('email', email)
    data.append('list_of_newsletter', newsletter)
    data.append('referrer', window.location.href)

    fetch(`/api/v2/json/newsletter/subscribe/`, {
        method: 'post',
        body: data
      }).then((response) => {
        if (response.status === 200) {
            document.querySelector('[data-ui-el="abonnement-newsletter"]')?.classList.add("hidden");
            document.querySelector('[data-ui-el="newsletter-success"]')?.classList.remove("hidden");
        }
      }).finally(() => {
        button.dataset.uiIsLoading = false.toString()
      })
}

const handleNewsletterForm = () => {
    const newsletterFormElements: NodeListOf<HTMLFormElement> = document.querySelectorAll('[data-ui-el="newsletter-form"]')

    newsletterFormElements.forEach((newsletterForm: HTMLFormElement) => {
        const button = newsletterForm.querySelector('[data-ui-el="newsletter-submit-button"]') as HTMLButtonElement
        const emailInput = document.querySelector('input[required][type="email"') as HTMLInputElement

        newsletterForm?.addEventListener('change', (formEvent) => {
            toggleSubmitButton(button, newsletterForm.checkValidity())
        })

        button?.addEventListener('click', (event) => {
            event.preventDefault()
            if (handleInputValidity(emailInput)) {
                submitForm(button, emailInput.value)
            } else {
                setTimeout(() => toggleSubmitButton(button, false), 0)
            }
        })
        
        emailInput?.addEventListener('input', () => {
            if (!handleInputValidity(emailInput)) {
                setTimeout(() => toggleSubmitButton(button, false), 0)
            }
        })
    })
}
